<template>
  <div
    class="item"
    :class="{
      'is-group': isGroup,
    }"
  >
    <AppIcon :name="item.icon" size="32px" is-img-tag class="coin-icon" />
    <div class="info">
      <div class="d-flex">
        <AppText size="15px" class="font-medium">
          {{ item.name }}
        </AppText>

        <slot name="tooltip" />
      </div>
      <slot v-if="!isGroup" name="amount">
        <FSkeleton
          variant="text"
          :is-loading="isLoading"
          height="17px"
          style="width: 120px;"
          transition-name="fade-absolute-1"
        >
          <div class="position-relative">
            <AppText size="14px" color="var(--color-black-04)">
              {{ item.payments }} {{ t('common.payment', item.payments) }}
            </AppText>
          </div>
        </FSkeleton>
      </slot>
    </div>
    <slot name="income">
      <div class="amount">
        <div class="income position-relative" style="">
          <FSkeleton
            variant="text"
            :is-loading="isLoading"
            height="18px"
            transition-name="fade-absolute-1"
          >
            <div class="position-relative">
              <CurrencyFormatter :value="item.incomeFiat" is-account-currency />
            </div>
          </FSkeleton>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';

export default {
  name: 'PrimaryCoin',
  components: {
    CurrencyFormatter,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  min-height: 77px;
  padding: 18px 0px;
  display: flex;
  gap: 20px;
  border-top: 1px solid var(--color-black-01);

  .info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 40px;
    justify-content: flex-start;;
  }

  &.is-group {
    padding-bottom: 10px;
    min-height: 68px;
    .info {
      justify-content: center;
    }
  }

  .amount {
    display: flex;
    align-items: center;

    .income {
      min-width: 100px;
      text-align: right;
      height: 18px;
      :deep(.text) {
        font-size: 15px;
        line-height: 1.2;
        @include font-medium;
      }
    }
  }
}

.coin-icon {
  position: relative;
  top: 4px;
}
</style>
