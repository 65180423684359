<template>
  <AppTooltip>
    <template #text>
      <AppText>
        {{ tipText }}
      </AppText>
    </template>

    <div
      tabindex="0"
      class="sort-button"
      :class="[sortClassInit, sortClass]"
      @click="onClick"
      @keypress.space="onSpacePress"
    >
      <AppIcon name="sort" size="36px" />
    </div>
  </AppTooltip>
</template>

<script>
import { computed, ref } from 'vue';

import AppTooltip from '@/components/AppTooltip.vue';

import { i18n } from '@/plugins/localization';

export default {
  name: 'SortButton',
  components: {
    AppTooltip,
  },
  props: {
    isSortDesc: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['action'],
  setup(props, { emit }) {
    const { t } = i18n.global;
    const tipText = computed(() => (props.isSortDesc ? t('common.sort.newestFirst') : t('common.sort.oldestFirst')));
    const sortClass = ref('');

    const sortClassInit = computed(() => {
      if (sortClass.value) {
        return '';
      }

      return props.isSortDesc ? 'desc-init' : 'asc-init';
    });

    const onClick = () => {
      sortClass.value = props.isSortDesc ? 'is-sort-asc' : 'is-sort-desc';
      emit('action');
    };

    const onSpacePress = (e) => {
      e.preventDefault();
      onClick();
    };

    return {
      onClick,
      onSpacePress,

      sortClassInit,
      sortClass,
      tipText,
    };
  },
};
</script>

<style lang="scss" scoped>
/* eslint-disable vue-scoped-css/no-unused-selector */
.sort-button {
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 4px;

  @include focus-visible;

  &:hover {
    background: #F2F2F2;

    :deep(.icon) {
      opacity: 1;
    }
  }

  :deep(.icon) {
    opacity: 0.2;
  }

  &.asc-init {
    :deep(.icon) {
      .group {
        transform: rotate(180deg);
        transform-origin: 22px 18px;
      }

      .arrow {
        transform: rotate(180deg);
        transform-origin: 12px 18px;
      }
    }
  }

  &.desc-init {
    :deep(.icon) {
      .group {
        transform-origin: 22px 18px;
      }

      .arrow {
        transform-origin: 12px 18px;
      }
    }
  }

  &.is-sort-desc {
    :deep(.icon) {
      .group {
        transform-origin: 22px 18px;
        animation: outanim 0.2s ease-in-out forwards;
      }

      .arrow {
        transform-origin: 12px 18px;
        animation: outanim 0.2s ease-in-out forwards;
      }
    }
  }

  &.is-sort-asc {
    :deep(.icon) {
      .group {
        transform: rotate(180deg);
        transform-origin: 22px 18px;
        animation: inanim 0.2s ease-in-out forwards;
      }

      .arrow {
        transform: rotate(180deg);
        transform-origin: 12px 18px;
        animation: inanim 0.2s ease-in-out forwards;
      }
    }
  }

  @keyframes inanim {
    from { transform: initial; }
    to { transform: rotate(180deg); }
  }

  @keyframes outanim {
    from { transform: rotate(180deg); }
    to { transform: initial; }
  }
}
</style>
