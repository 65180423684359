<template>
  <div class="monthly-income">
    <div class="header">
      <div class="flex-grow-1">
        <AppText
          variant="div"
          size="17px"
          class="font-medium"
        >
          {{ t('merchantDashboard.monthlyIncome.title') }}
        </AppText>
        <AppText
          variant="div"
          size="12px"
          opacity="0.5"
          mt="6px"
          :line-height="1.3"
        >
          {{ t('merchantDashboard.monthlyIncome.description') }}
        </AppText>
      </div>
      <SortButton :is-sort-desc="isSortDesc" @action="isSortDesc = !isSortDesc" />
      <FSelect
        v-model="choosedYear"
        class="years-select"
        :options="yearsList"
        has-arrow
        no-margin
        size="x-small"
        popper-class="header-select-popper"
      />
    </div>

    <div class="items">
      <MonthlyIncomeItem
        v-for="(item, i) in data"
        :key="i"
        :is-loading="isLoading"
        :item="item"
      />
    </div>
  </div>
</template>

<script setup>
import {
  computed, ref, watch, watchEffect,
} from 'vue';

import { MODULES_NAMES } from '@/common/modules';
import { updateTable, useTablesStore } from '@/store';

import MonthlyIncomeItem from './MonthlyIncomeItem.vue';
import SortButton from './SortButton.vue';

const props = defineProps({
  yearsList: {
    type: Object,
    default: () => ({}),
  },
  incomeData: {
    type: Object,
    default: () => ({}),
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const choosedYear = ref();

watchEffect(() => {
  if (props.yearsList) {
    choosedYear.value = props.yearsList[0]?.value || (new Date()).getFullYear();
  }
});

const isSortDesc = ref(false);

const data = computed(() => {
  const res = [];

  if (props.incomeData[choosedYear.value]) {
    props.incomeData[choosedYear.value].forEach((item, i) => {
      res.push({
        sn: i > 8 ? (i + 1).toString() : `0${i + 1}`,
        ...item,
      });
    });
  }

  if (isSortDesc.value) {
    return res;
  }

  return res.reverse();
});

const moduleName = MODULES_NAMES.MERCHANT_DASHBOARD;
const initStore = () => {
  const tableStore = useTablesStore();

  const dashboardStore = tableStore.getCurrentTable({ moduleName });
  const sortValueFromStore = dashboardStore?.isSortDesc;
  isSortDesc.value = sortValueFromStore ?? true;
};

watch(isSortDesc, (val) => {
  updateTable({
    moduleName,
    field: 'isSortDesc',
    value: val,
  });
});

initStore();
</script>

<style lang="scss" scoped>
.monthly-income {
  width: 100%;
  max-width: 550px;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .items {
    margin-top: 25px;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    align-items: center;
  }

  .years-select {
    width: 80px;
  }
}

:global(.header-select-popper) {
  width: 155px;
  min-width: 155px !important;
}

:global(.header-select-popper .el-select-dropdown__item) {
  height: 44px;
}
</style>
