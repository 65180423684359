<template>
  <div class="item">
    <AppIcon :name="item.icon" size="24px" is-img-tag class="coin-icon" />
    <div class="info">
      <AppText class="font-medium" style="margin-right: 6px;">
        {{ item.name }}
      </AppText>
      <AppText color="var(--color-black-04)">
        ({{ item.family }})
      </AppText>
      <DotSeparator size="5px" margin="2px 8px 0" color="var(--color-black-04)" />
      <FSkeleton
        variant="text"
        :is-loading="isLoading"
        height="13px"
        style="width: 120px;"
        transition-name="fade-absolute-1"
      >
        <div class="position-relative">
          <AppText size="13px" color="var(--color-black-04)">
            {{ item.payments }} {{ t('common.payment', item.payments) }}
          </AppText>
        </div>
      </FSkeleton>
    </div>
    <slot name="income">
      <div class="amount">
        <div class="income position-relative" style="">
          <FSkeleton
            variant="text"
            :is-loading="isLoading"
            height="10px"
            transition-name="fade-absolute-1"
          >
            <div class="position-relative">
              <CurrencyFormatter :value="item.incomeFiat" is-account-currency />
            </div>
          </FSkeleton>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import DotSeparator from '@/components/DotSeparator.vue';

export default {
  name: 'SmallCoin',
  components: {
    CurrencyFormatter,
    DotSeparator,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  gap: 16px;
  padding-left: 20px;
  height: 40px;
  align-items: center;

  .info {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
}
</style>
