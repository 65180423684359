<template>
  <div class="row">
    <div class="cell">
      <AppText
        opacity="0.4"
        variant="div"
        :line-height="1.35"
      >
        {{ item.sn }}.
      </AppText>
    </div>
    <div class="cell flex-grow-1">
      <AppText
        variant="div"
        size="14px"
        :line-height="1.35"
        pl="12px"
      >
        {{ t(`month.${item.month.toLowerCase()}`) }}
      </AppText>
    </div>
    <div class="cell justify-content-end" style="min-width: 140px;">
      <FSkeleton
        variant="text"
        :is-loading="isLoading"
        height="19px"
        style="min-width: 120px; height: 19px;"
        transition-name="fade-absolute-1"
      >
        <div class="position-relative">
          <AppText variant="div" size="14px" :line-height="1.35" style="text-align: right;">
            {{ item.data.payments }} {{ t('common.payment', item.data.payments) }}
          </AppText>
        </div>
      </FSkeleton>
    </div>
    <div class="cell" style="min-width: 180px;">
      <FSkeleton
        variant="text"
        :is-loading="isLoading"
        height="19px"
        style="min-width: 140px; height: 19px;"
        transition-name="fade-absolute-1"
      >
        <div class="position-relative" style="text-align: right;">
          <CurrencyFormatter
            variant="div"
            size="14px"
            :line-height="1.35"
            class="font-medium"
            :value="item.data.total"
            is-account-currency
          />
        </div>
      </FSkeleton>
    </div>
  </div>
</template>

<script>
import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';

export default {
  name: 'MonthlyIncomeItem',
  components: {
    CurrencyFormatter,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: contents;

  .cell {
    height: 48px;
    padding: 14px 0;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--color-black-01);
    // min-width: 68px;

    &:last-child {
      justify-content: flex-end;
    }
  }
}
</style>
