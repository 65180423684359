<template>
  <div class="total-received">
    <div>
      <div class="title">
        <AppText size="17px" :line-height="1" class="font-medium">
          {{ t('merchantDashboard.totalRecieved.title') }}
        </AppText>
        <AppTooltip text-align="left" width="252px" cursor="default">
          <AppIcon name="info-circle-medium" size="18px" :opacity="0.4" is-img-tag />

          <template #text>
            {{ t('merchantDashboard.totalRecieved.tooltip') }}
          </template>
        </AppTooltip>
      </div>
      <AppText
        size="12px"
        opacity="0.5"
        variant="div"
        mt="6px"
      >
        {{ t('merchantDashboard.totalRecieved.description') }}
      </AppText>
    </div>

    <div class="items">
      <template v-for="(item, index) in primaryCoins" :key="index">
        <PrimaryCoin :item="item" :is-loading="isLoading" />
      </template>
      <PrimaryCoin
        :item="{
          icon: 'coins-group-active',
          name: t('common.thirdPartyTokens'),
          incomeFiat: thirdPartyTokensIncome,
        }"
        is-group
        :is-loading="isLoading"
      >
        <template #tooltip>
          <AppTooltip text-align="center" cursor="default">
            <template #text>
              {{ t('merchantDashboard.totalRecieved.thirdPartyTokensTooltip') }}
            </template>
            <AppIcon
              name="info-circle-medium"
              size="18px"
              :opacity="0.4"
              is-img-tag
              style="margin-left: 8px;"
            />
          </AppTooltip>
        </template>
      </PrimaryCoin>
      <template v-for="(item, index) in thirdPartyCoins" :key="index">
        <SmallCoin :item="item" :is-loading="isLoading" />
      </template>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import AppTooltip from '@/components/AppTooltip.vue';

import { addThousandsSeparators } from '@/utils/numberFormatter';
import { fullCoinsArray, coinsSortWeight } from '@/common/data';

import PrimaryCoin from './PrimaryCoin.vue';
import SmallCoin from './SmallCoin.vue';

export default {
  name: 'TotalReceived',
  components: {
    AppTooltip,
    PrimaryCoin,
    SmallCoin,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      deafult: false,
    },
  },
  setup(props) {
    const allCoins = computed(() => {
      if (!props.data) return [];

      return Object.entries(props.data).map((item) => {
        const [key, data] = item;
        const coin = fullCoinsArray.find((c) => c.value === key.toUpperCase());

        return {
          coin: key,
          payments: data.payments,
          income: data.income,
          incomeFiat: data.incomeFiat,
          ...coin,
        };
      })
        .sort((a, b) => coinsSortWeight[a.coin] - coinsSortWeight[b.coin]);
    });

    const primaryCoins = computed(() => allCoins.value.filter((item) => item.isPrimary));
    const thirdPartyCoins = computed(() => allCoins.value.filter((item) => !item.isPrimary && item.incomeFiat));

    const thirdPartyTokensIncome = computed(() => thirdPartyCoins.value.reduce((acc, elem) => acc + elem.incomeFiat, 0));

    return {
      primaryCoins,
      thirdPartyCoins,
      thirdPartyTokensIncome,
      addThousandsSeparators,
    };
  },
};
</script>

<style lang="scss" scoped>
.total-received {
  width: 100%;
  max-width: 550px;

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .items {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    :deep(.item:last-child) {
      border-bottom: none;
    }
  }

  :deep(.tooltip) {
    display: flex;
    align-items: center;
  }
}
</style>
